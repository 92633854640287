import { type Config } from 'tailwindcss'

export const extendedTheme = {
	colors: {
		border: 'hsla(var(--border), <alpha-value>)',
		input: {
			DEFAULT: 'hsla(var(--input), <alpha-value>)',
			invalid: 'hsla(var(--input-invalid), <alpha-value>)',
		},
		ring: {
			DEFAULT: 'hsla(var(--ring), <alpha-value>)',
			invalid: 'hsla(var(--foreground-destructive), <alpha-value>)',
		},
		background: 'hsla(var(--background), <alpha-value>)',
		foreground: {
			DEFAULT: 'hsla(var(--foreground), <alpha-value>)',
			destructive: 'hsla(var(--foreground-destructive), <alpha-value>)',
		},
		primary: {
			DEFAULT: 'hsla(var(--primary), <alpha-value>)',
			30: 'hsla(var(--primary-30), <alpha-value>)',
			40: 'hsla(var(--primary-40), <alpha-value>)',
			50: 'hsla(var(--primary-50), <alpha-value>)',
			60: 'hsla(var(--primary-60), <alpha-value>)',
			70: 'hsla(var(--primary-70), <alpha-value>)',
			80: 'hsla(var(--primary-80), <alpha-value>)',
			90: 'hsla(var(--primary-90), <alpha-value>)',
			120: 'hsla(var(--primary-120), <alpha-value>)',
			130: 'hsla(var(--primary-130), <alpha-value>)',
			140: 'hsla(var(--primary-140), <alpha-value>)',
			150: 'hsla(var(--primary-150), <alpha-value>)',
			160: 'hsla(var(--primary-160), <alpha-value>)',
			170: 'hsla(var(--primary-170), <alpha-value>)',
			foreground: 'hsla(var(--primary-foreground), <alpha-value>)',
		},
		secondary: {
			DEFAULT: 'hsla(var(--secondary), <alpha-value>)',
			30: 'hsla(var(--secondary-30), <alpha-value>)',
			40: 'hsla(var(--secondary-40), <alpha-value>)',
			50: 'hsla(var(--secondary-50), <alpha-value>)',
			60: 'hsla(var(--secondary-60), <alpha-value>)',
			70: 'hsla(var(--secondary-70), <alpha-value>)',
			80: 'hsla(var(--secondary-80), <alpha-value>)',
			90: 'hsla(var(--secondary-90), <alpha-value>)',
			120: 'hsla(var(--secondary-120), <alpha-value>)',
			140: 'hsla(var(--secondary-140), <alpha-value>)',
			foreground: 'hsla(var(--secondary-foreground), <alpha-value>)',
		},
		destructive: {
			DEFAULT: 'hsla(var(--destructive), <alpha-value>)',
			foreground: 'hsla(var(--destructive-foreground), <alpha-value>)',
		},
		muted: {
			DEFAULT: 'hsla(var(--muted), <alpha-value>)',
			foreground: 'hsla(var(--muted-foreground), <alpha-value>)',
		},
		accent: {
			DEFAULT: 'hsla(var(--accent), <alpha-value>)',
			30: 'hsla(var(--accent-30), <alpha-value>)',
			40: 'hsla(var(--accent-40), <alpha-value>)',
			50: 'hsla(var(--accent-50), <alpha-value>)',
			60: 'hsla(var(--accent-60), <alpha-value>)',
			70: 'hsla(var(--accent-70), <alpha-value>)',
			80: 'hsla(var(--accent-80), <alpha-value>)',
			90: 'hsla(var(--accent-90), <alpha-value>)',
			120: 'hsla(var(--accent-120), <alpha-value>)',
			130: 'hsla(var(--accent-130), <alpha-value>)',
			140: 'hsla(var(--accent-140), <alpha-value>)',
			150: 'hsla(var(--accent-150), <alpha-value>)',
			160: 'hsla(var(--accent-160), <alpha-value>)',
			170: 'hsla(var(--accent-170), <alpha-value>)',
			foreground: 'hsla(var(--accent-foreground), <alpha-value>)',
		},
		popover: {
			DEFAULT: 'hsla(var(--popover), <alpha-value>)',
			foreground: 'hsla(var(--popover-foreground), <alpha-value>)',
		},
		card: {
			DEFAULT: 'hsla(var(--card), <alpha-value>)',
			foreground: 'hsla(var(--card-foreground), <alpha-value>)',
		},
		navbar: {
			DEFAULT: 'hsla(var(--navbar), <alpha-value>)',
			foreground: 'hsla(var(--navbar-foreground), <alpha-value>)',
		},
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
	},
	fontSize: {
		// 1rem = 16px
		/** 80px size / 84px high / bold */
		mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
		/** 56px size / 62px high / bold */
		h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
		/** 40px size / 48px high / bold */
		h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
		/** 32px size / 36px high / bold */
		h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 28px size / 36px high / bold */
		h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
		/** 24px size / 32px high / bold */
		h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
		/** 16px size / 20px high / bold */
		h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '700' }],

		/** 32px size / 36px high / normal */
		'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
		/** 28px size / 36px high / normal */
		'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
		/** 24px size / 32px high / normal */
		'body-lg': ['1.5rem', { lineHeight: '2rem' }],
		/** 20px size / 28px high / normal */
		'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
		/** 16px size / 20px high / normal */
		'body-sm': ['1rem', { lineHeight: '1.25rem' }],
		/** 14px size / 18px high / normal */
		'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
		/** 12px size / 16px high / normal */
		'body-2xs': ['0.75rem', { lineHeight: '1rem' }],

		/** 18px size / 24px high / semibold */
		caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
		/** 16px size / 24px high / bold */
		button: ['1rem', { lineHeight: '1.5rem', fontWeight: '700' }],
	},
	keyframes: {
		'accordion-down': {
			from: { height: '0' },
			to: { height: 'var(--radix-accordion-content-height)' },
		},
		'accordion-up': {
			from: { height: 'var(--radix-accordion-content-height)' },
			to: { height: '0' },
		},
	},
	animation: {
		'accordion-down': 'accordion-down 0.2s ease-out',
		'accordion-up': 'accordion-up 0.2s ease-out',
	},
	opacity: {
		'90': '.9',
	},
	borderWidth: {
		'3': '3px',
	},
	ringWidth: {
		'3': '3px',
	},
	height: {
		'8.5': '2.125rem',
	},
	lineHeight: {
		'5.5': '1.375rem',
	},
	margin: {
		'13': '3.25rem',
	},
} satisfies Config['theme']
